body::-webkit-scrollbar {
	width: 0.5em;
}
body::-webkit-scrollbar-thumb {
	background-color: darkgrey;
}

.main__login {
	background: linear-gradient(
		116.49deg,
		#283342 42.02%,
		rgba(91, 116, 131, 0.87) 99.51%
	);
}

.scroll__hide::-webkit-scrollbar{
	width: 0;
}

.scroll__hide::-webkit-scrollbar-thumb{
	background-color: transparent;
	border-radius: 0;
}

.users::-webkit-scrollbar,
.chat::-webkit-scrollbar {
	width: 0.2em;
}

.users::-webkit-scrollbar-thumb,
.chat::-webkit-scrollbar-thumb {
	background-color: darkgrey;
	border-radius: 50px;
}

.prescriptions::-webkit-scrollbar {
	width: 0.5em;
}

.prescriptions::-webkit-scrollbar-thumb {
	background-color: white;
	border-radius: 50px;
}
.patient__prescriptions::-webkit-scrollbar {
	width: 0.3em;
}

.patient__prescriptions::-webkit-scrollbar-thumb {
	background-color: gray;
	border-radius: 50px;
}
.ward__list::-webkit-scrollbar {
	width: 0.2em;
}
.ward__list::-webkit-scrollbar-thumb {
	background-color: darkgrey;
	border-radius: 50px;
}

.shadow__bottom {
	box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08);
}

.horizontal__scrollbar::-webkit-scrollbar {
	height: 0.25em;	
}

.horizontal__scrollbar::-webkit-scrollbar-thumb {
	background-color: gray;
}

.inner__shadow__1 {
	box-shadow: rgba(50, 50, 93, 0.092) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.112) 0px 18px 36px -18px inset;
	/* box-shadow: rgba(0, 0, 0, 1) inset; */
}
.inner__shadow__2 {
	box-shadow: rgba(238, 226, 70, 0.25) 0px 30px 60px -12px inset, rgba(130, 125, 56, 0.3) 0px 18px 36px -18px inset;
	/* box-shadow: rgba(0, 0, 0, 1) inset; */
}
.inner__shadow__3 {
	box-shadow: rgba(170, 50, 181, 0.344) 0px 30px 60px -12px inset, rgba(144, 65, 181, 0.581) 0px 18px 36px -18px inset;
	/* box-shadow: rgba(0, 0, 0, 1) inset; */
}